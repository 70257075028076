@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
*{
  padding: 0px;
  margin: 0px;
  font-family: 'Montserrat';
}
input[type="file"] {
  display: none;
}
a:link {
  text-decoration: none;
}
.container {
  font-family: Montserrat;
}
.content {
  display: flex;
  flex-direction: row;

}
.leftSide {
  background-color: #E60000;
  color: #FFF;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-inline: 50px;
  width: 50%;
}
.rightSide {
  background-color: #E3E3E3;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-inline: 50px;
}
.contact {
  align-self: end;
}
.wellcome {
  font-size: 77px;
  font-weight: bold;
  margin-bottom: 10px;
}
.wellcome-desc {
  font-size: 20px;
  font-weight: 300;
}
.wellcome-box {
  margin-bottom: 150px;
  align-self: end;
}
.contact-us {
  font-size: 16px;
  font-weight: 300;
  width: 450px;
}
.whatsapp {
  margin-top: 20px;
  width: 130px;
  display: flex;
  padding: 10px;
  color: #E60000;
  font-size: 15px;
  font-weight: 600;
  flex-direction: row;
  border: none;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: 'Montserrat';
}
.logoTopHome {
  width: 200px;
}

.btnCatalogo {
  margin-top: 100px;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 15px;
  color: #FFF;
  background-color: #E60000;
  border-radius: 5px;
  border: none;
  padding: 20px 50px 20px 50px;
  cursor: pointer;
  width: fit-content;
}
.rodape {
  bottom: 0;
  background-color: #000;
  color: #A7A8AA;
  width: 100%;
  display: flex;
  justify-content: center;
}
.rodape-content {
  display: flex;
  flex-direction: row;
  padding: 40px;
  justify-content: space-between;
  width: 1140px;
}
.logoRodape {
  width: 100px;
  margin-right: 40px;
  margin-left: 40px;
}
.rodapeLink {
  margin-inline: 20px;
  cursor: pointer;
  font-size: 14px;
}
.rodapeDir {
  margin-inline: 20px;
  font-size: 14px;
}
.rodapeInfo {
  margin-left: 20px;
  align-content: end;
}
.rodapeSocial {
  align-content: end;

}
/* header */
.bg {
  background-image: url('../public/images/bg.jpg') ;
  background-size: cover;
  height: 300px;
  color: #FFF;
  display: flex;
}
.headContainer {
  margin-left: auto;
  margin-right: auto;
  width: 1140px;
  align-self: center;
}
.headTitle {
  font-weight: 800;
  font-size: 55px;
  margin-bottom: 20px;
}
.headCaminho {
  display: flex;
  flex-direction: row;
}
.caminho {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.bodyContainer {
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  max-width: 1140px;
  min-height: 431px;
}
.MainButton {
  margin: 15px;
  height: 100px;
  width: 350px;
  border-radius: 10px;
  background-image: linear-gradient(190deg, #E60000 0%, #b60000 100%);
  align-content: center;
  cursor: pointer;
}
.MainButtonText {
  display: flex;
  color: #FFF;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  font-size: 25px;
  padding-left: 30px;
}
.CardProduto {
  width: 250px;
  border-radius: 10px;
  background-color: #f1f1f1;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2);
  margin: 10px;
}
.CardProdCont {
  display: flex;
  flex-direction: column;
}
.fotoProd {
  align-self: center;
  max-width: 250px;
  max-height: 250px;
}
.CardProdInfos {
  background-color: #e3e3e3;
  border-radius: 0px 0px 10px 10px;
}
.prodNome {
  padding: 10px 10px 0px 10px;
  font-size: 18px;
  font-weight: 800;
  color: #000;
  height: 70px;
}
.prodPreco {
  padding: 0px 10px 10px 10px;
  font-size: 25px;
  font-weight: 800;
  color: #E60000;
}

/* LOGIN */
.bg-login {
  background-image: url('../public/images/bg.jpg');
  background-size:cover;
  background-repeat: no-repeat;
  height: 100vh;
}
.loginContainer {
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100vh;
}
.loginContent {
  background-color: #E3E3E3;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logoLogin {
  height: 85px;
  width: 85px;
}
.formLogin {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

/* ADMIN */
.adminContainer {
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100vh;
}
.adminContent {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  width: 1400px;
  height: 650px;
}
.LeftSideAdmin {
  width: 100%;
}
.rightSideAdmin {
  width: 300px;
}
.line {
  width: 0;
  height: 100%;
  border: 1px solid #C4C4C4;
  margin-inline: 10px;
  display: inline-block;
}
.btnUpload {
  background-color: #b60000;
  color: #FFF;
  border-radius: 5px;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  text-align: center;
}
.btnUpload:hover {
  background-color: #920000;
}
.prodFotoColumn {
  display: flex;
  flex-direction: column;
}
.fotoBanco {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 100%;
  width: 100%;
}
@media (max-width:1230px) {
  .headTitle {
    margin-left: 40px;
  }
  .headCaminho {
    margin-left: 20px;
  }
}

@media (max-width:750px) {
  .content {
    flex-direction: column;
  }
  .leftSide {
    height: auto;
    width: 100%;
    padding-inline: 0px;
    align-items: center;
    text-align: center;
    
  }
  .wellcome-box {
    margin-bottom: 20px;
    margin-top: 20px;
    align-self: center;
  }
  .contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    align-self: center;
  }
  .rightSide {
    height: auto;
    width: 100%;
    padding: 0;
    align-items: center;
  }
  .logoTopHome {
    width: 150px;
    margin-top: 30px;
  }
  .btnCatalogo {
    margin-top: 30px;
    margin-bottom: 60px;
  }
  .rodape-content {
    flex-direction: column;
    align-items: center;
  }
  .rodapeInfo {
    margin-left: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
  .headTitle {
    margin-left: 40px;
    font-size: 40px;
  }
  .headCaminho {
    margin-left: 20px;
    flex-direction: column;
  }
  .MainButton {
    width: 330px;
  }
  .MainButtonText {
    padding-left: 15px;
  }
}